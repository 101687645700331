import {
  chargebackSearchEnabled,
  chargebackGetEnabled,
  chargebackCreateUploadDisputeEnabled,
  chargebackViewUploadDisputeEnabled,
  chargebackCreateDisputeEnabled,
  chargebackCreateBatchUploadDisputeEnabled
} from '@/actions-permission/chargeback.permissions'

const routeChargeback = store => ({
  path: 'chargebacks/',
  name: 'chargebacks',
  component: () =>
    /* webpackChunkName: "chargebacks" */ import(
      '@/views/Chargebacks/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (!chargebackSearchEnabled(store.getters.permissionIsEnabled)) {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    navigationBar: {
      label: 'breadcrumb.chargeback.title',
      navigationAction: $router => ({
        click: () => $router.push({ name: 'chargebackMassRepresentment' }),
        label: 'breadcrumb.chargeback.mass-representment'
      })
    }
  }
})

const routeChargebackMassRepresentment = store => ({
  path: 'chargebacks/mass-representment',
  name: 'chargebackMassRepresentment',
  component: () =>
    /* webpackChunkName: "chargebacks" */ import(
      '@/views/Chargebacks/MassRepresentment/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (
      !chargebackViewUploadDisputeEnabled(store.getters.permissionIsEnabled) ||
      !chargebackCreateBatchUploadDisputeEnabled(
        store.getters.permissionIsEnabled
      ) ||
      !chargebackCreateDisputeEnabled(store.getters.permissionIsEnabled)
    ) {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }

    next()
  },
  meta: {
    navigationBar: {
      label: 'chargeback.mass-representment',
      navigateTo: () => ({
        name: 'chargebacks'
      })
    }
  }
})

const routeChargebackDetails = store => ({
  path: 'chargebacks/:chargeback_id',
  name: 'chargebackDetails',
  component: () =>
    /* webpackChunkName: "chargebacks" */ import(
      '@/views/Chargebacks/Details/index.vue'
    ),
  beforeEnter: async (to, from, next) => {
    if (!chargebackGetEnabled(store.getters.permissionIsEnabled)) {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }

    const { chargeback_id: chargebackId } = to.params

    store.dispatch('GET_ONE_CHARGEBACK', {
      chargebackId
    })

    next()
  },
  meta: {
    navigationBar: {
      label: 'chargeback.details',
      navigateTo: () => ({
        name: 'chargebacks'
      })
    }
  }
})

const routeChargebackRepresentment = store => ({
  path: 'chargebacks/:chargeback_id/representment',
  name: 'chargebackRepresentment',
  component: () =>
    /* webpackChunkName: "chargebacks" */ import(
      '@/views/Chargebacks/Representment/index.vue'
    ),
  beforeEnter: async (to, from, next) => {
    if (
      !chargebackViewUploadDisputeEnabled(store.getters.permissionIsEnabled) ||
      !chargebackCreateUploadDisputeEnabled(
        store.getters.permissionIsEnabled
      ) ||
      !chargebackCreateDisputeEnabled(store.getters.permissionIsEnabled)
    ) {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }

    const { chargeback_id: chargebackId } = to.params
    if (!store.getters.chargeback) {
      await store.dispatch('GET_ONE_CHARGEBACK', {
        chargebackId
      })
    }
    next()
  },
  meta: {
    navigationBar: {
      label: 'chargeback.representment',
      navigateTo: params => ({
        name: 'chargebackDetails',
        params
      })
    }
  }
})

export default [
  routeChargebackMassRepresentment,
  routeChargeback,
  routeChargebackDetails,
  routeChargebackRepresentment
]
